import _, { cloneDeep } from 'lodash'
import geohash from 'ngeohash'

import {
  IGetMyEventsFilteredDataItem,
  IMyEvent,
} from './event-registered-users/event-registered-users.interface'
import {
  IEventStaffInterface,
  IEventStaffInterfaceTypes as iesit,
} from './event-staff/event-staff.interface'
import { IHorseData } from './horse/horse.interface'
import { INotificationSettings, ISelect, ITeamMember, IUserInterface } from './users/user.interface'

import helpers from '../commonHelpers/helpers'
import { CONST } from '../const/const'
import { CountryList } from '../fakeData/countryList'
import FirestoreService from '../services/firestoreService'
import { IEventDetailData } from './event-drafts/event-draft.interface'
import { EventReviewPublishModel } from './event-review-publish/event-review-publish.model'
import { EventModel } from './events/event.model'
import { IRegisterTabsSelectData, IRiderTeamMember } from './users/user.interface'
import IRegistrationTeamsTypes from './registeration-teams/registration-teams.interface'
import { getUserFullName } from '../helpers/helpers'
import { IRiderTeamMemberInterface } from './rider-team-member/riderTeamMember.interface'
import TimeLib from '../lib/Time'
import { EventFeesModel } from './event-fees/event-fees.model'
import { EventStaffModel } from './event-staff/event-staff.model'
import { EventVendorsModel } from './event-vendors/event-vendors.model'
import { EventTicketingModel } from './event-ticketing/event-ticketing.model'
import { EventDetailsModel } from './event-details/event-details.model'
import { EventSchedulingModel } from './event-scheduling/event-scheduling.model'
import { EventPoliciesModel } from './event-policies/event-policies.model'
import { EventSponsorsModel } from './event-sponsors/event-sponsors.model'
import { EventPaperworkModel } from './event-paperwork/event-paperwork.model'
import EventPaymentSettingsModel from './event-payment-settings/event-payment-settings.model'

interface IDateInterace {
  seconds: number
  nanoseconds: number
}

interface IUserExtendedWithSelected extends IUserInterface {
  selected?: boolean
}

type IArgObj = Record<any | IDateInterace, any | IDateInterace>
type IGetValueFromListType = (args: {
  list: ISelect[]
  valueToFind: string
  valueToGet?: 'label' | 'value' | 'ccode'
}) => ISelect | undefined | null
type IGetStructuredTeamMembersList = (teamMembers: ITeamMember[]) => ITeamMember[]

// Constants
const staffCategoriesArray = [
  {
    title: 'Management',
    category: ['show manager', 'secretary', 'course designers', 'technical delegate', 'announcers'],
    logo: '/assets/cp_icons/MySpace-1.svg',
  },
  {
    title: 'Judges & scorers',
    category: ['judges'],
    logo: '/assets/cp_icons/Law-1.svg',
  },
  {
    title: 'Stewards & ingates',
    category: ['stewards', 'ingates'],
    logo: '/assets/cp_icons/Paste-1.svg',
  },
  {
    title: 'Veterinarians & farriers',
    category: ['farrier', 'veterinarian'],
    logo: '/assets/cp_icons/Heart-1.svg',
  },
  {
    title: 'Volunteers',
    category: ['volunteers'],
    logo: '/assets/cp_icons/Cancer Ribbon-1.svg',
  },
]

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
const getNotificationSettings = (obj?: INotificationSettings) => {
  return {
    newMessages: obj?.newMessages ?? false,
    weatherUpdates: obj?.weatherUpdates ?? false,
    resultUpdates: obj?.resultUpdates ?? false,
    courseUpdates: obj?.courseUpdates ?? false,
    paymentUpdates: obj?.paymentUpdates ?? false,
  }
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const getSerializedRiderTeamMembers = (ridersTeamMembers: IRiderTeamMemberInterface[]) => {
  let ridersMap: Map<
    string,
    {
      riders: IRiderTeamMemberInterface[]
      roles: Set<string>
    }
  > = new Map()

  let selectedRoles: Set<string> = new Set()

  ridersTeamMembers.forEach((currRiderTeamMember) => {
    if (currRiderTeamMember.teamMemberId) {
      selectedRoles = new Set()

      if (ridersMap.get(currRiderTeamMember.teamMemberId)?.roles)
        selectedRoles = ridersMap.get(currRiderTeamMember.teamMemberId)?.roles!
      else selectedRoles.clear()

      if (currRiderTeamMember.teamMemberRole && currRiderTeamMember.delete !== true)
        selectedRoles.add(currRiderTeamMember.teamMemberRole)

      ridersMap.set(currRiderTeamMember.teamMemberId, {
        riders: [
          ...(ridersMap.get(currRiderTeamMember.teamMemberId)?.riders ?? []),
          {
            ...currRiderTeamMember,
          },
        ].map((currRiderTeamMember_) => {
          return {
            ...currRiderTeamMember_,
            roles: CONST.UI.ROLES.map((role) => {
              return {
                ...role,
                selected: selectedRoles.has(role.value),
              }
            }),
          }
        }),
        roles: selectedRoles,
      })
    }
  })

  return Array.from(ridersMap).flatMap((curr) => {
    return curr[1].riders
  })
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// Returns filtered horsedata
const getFilteredHorseData = (obj?: IHorseData): IHorseData => {
  let objToReturn: IHorseData = {
    id: '',
    horseOwnerId: null,
  }

  objToReturn.id = obj?.id ?? ''
  objToReturn.horseId = obj?.horseId ?? ''
  objToReturn.horseOwnerId = obj?.horseOwnerId ?? ''
  objToReturn.horseName = obj?.horseName ?? ''
  objToReturn.horseBreed =
    (typeof obj?.horseBreed === 'string'
      ? obj?.horseBreed
      : (obj?.horseBreed as ISelect)?.value || '') ?? ''
  objToReturn.horseProfilePicture = obj?.horseProfilePicture ?? ''
  objToReturn.horseHeight =
    typeof obj?.horseHeight === 'string'
      ? obj?.horseHeight
      : (obj?.horseHeight as ISelect)?.value || ''
  objToReturn.horseDob = obj?.horseDob ?? new Date()
  objToReturn.horseGender =
    typeof obj?.horseGender === 'string'
      ? obj?.horseGender
      : (obj?.horseGender as ISelect)?.value || ''
  objToReturn.horseDiscipline =
    typeof obj?.horseDiscipline === 'string'
      ? obj?.horseDiscipline
      : (obj?.horseDiscipline as ISelect)?.value || ''
  objToReturn.horseColor =
    typeof obj?.horseColor === 'string'
      ? obj?.horseColor
      : (obj?.horseColor as ISelect)?.value || ''
  objToReturn.horseMarking = (function () {
    let valueToReturn: string[] = []

    valueToReturn =
      typeof obj?.horseMarking === 'object' && Array.isArray(obj?.horseMarking)
        ? obj?.horseMarking?.filter((curr) => typeof curr === 'string')
        : []

    if (typeof obj?.horseMarking === 'string') valueToReturn = [obj?.horseMarking]
    return valueToReturn
  })()
  objToReturn.horseCountry =
    typeof obj?.horseCountry === 'string'
      ? obj?.horseCountry
      : (obj?.horseCountry as ISelect)?.value || ''
  objToReturn.horseMicrochipNumber = obj?.horseMicrochipNumber ?? ''
  objToReturn.horsePassportNumber = obj?.horsePassportNumber ?? ''
  objToReturn.horseTeamMembers = obj?.horseTeamMembers ?? []
  objToReturn.horseStatus = obj?.horseStatus ?? ''
  objToReturn.horseZone =
    typeof obj?.horseZone === 'string' ? obj?.horseZone : (obj?.horseZone as ISelect)?.value || ''
  objToReturn.horsesOther = obj?.horsesOther ?? []
  objToReturn.horseUsdfNumber = obj?.horseUsdfNumber || ''
  objToReturn.horseUsdfExpiration = obj?.horseUsdfExpiration || ''
  objToReturn.horseUseaNumber = obj?.horseUseaNumber || ''
  objToReturn.horseUseaExpiration = obj?.horseUseaExpiration || ''
  objToReturn.horseUshjaNumber = obj?.horseUshjaNumber || ''
  objToReturn.horseUshjaExpiration = obj?.horseUshjaExpiration || ''
  objToReturn.horseAhhsNumber = obj?.horseAhhsNumber || ''
  objToReturn.horseAhhsExpiration = obj?.horseAhhsExpiration || ''
  objToReturn.horseAmhaNumber = obj?.horseAmhaNumber || ''
  objToReturn.horseAmhaExpiration = obj?.horseAmhaExpiration || ''
  objToReturn.horseArhpaNumber = obj?.horseArhpaNumber || ''
  objToReturn.horseArhpaExpiration = obj?.horseArhpaExpiration || ''
  objToReturn.horseAshaNumber = obj?.horseAshaNumber || ''
  objToReturn.horseAshaExpiration = obj?.horseAshaExpiration || ''
  objToReturn.horseUphaNumber = obj?.horseUphaNumber || ''
  objToReturn.horseUphaExpiration = obj?.horseUphaExpiration || ''
  objToReturn.horseWdaaNumber = obj?.horseWdaaNumber || ''
  objToReturn.horseWdaaExpiration = obj?.horseWdaaExpiration || ''
  return objToReturn
}

const getCategorizedEventDetails = (events: IEventDetailData[]) => {
  let members: ITeamMember[] = []
  let riders: IRiderTeamMember[] = []
  let horses: IRegisterTabsSelectData[] = []

  let events_ = cloneDeep(events)

  events_.forEach((currOneDayRegistration) => {
    currOneDayRegistration.members.forEach((currentMember) => {
      if (currentMember?.horses?.length) members.push(currentMember)
      currentMember?.horses?.forEach((currHorse) => {
        horses.push(currHorse)
      })
      currentMember?.riderTeamMembers?.forEach((currRider) => {
        riders.push(currRider)
      })
    })
  })

  return {
    riders,
    horses,
    members,
  }
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/* Calculate the upper and lower boundary geohashes for
 a given latitude, longitude, and distance in miles */
const getGeohashRange = (
  latitude: number,
  longitude: number,
  distance: number // miles
) => {
  const lat = 0.0144927536231884 // degrees latitude per mile
  const lon = 0.0181818181818182 // degrees longitude per mile

  const lowerLat = latitude - lat * distance
  const lowerLon = longitude - lon * distance

  const upperLat = latitude + lat * distance
  const upperLon = longitude + lon * distance

  const lower = geohash.encode(lowerLat, lowerLon)
  const upper = geohash.encode(upperLat, upperLon)

  return {
    lower,
    upper,
  }
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
const getUtcDate = (date?: Date | string): string => {
  let currentUtcDate: Date = new Date()
  if (date) currentUtcDate = new Date(date)
  const dateString =
    currentUtcDate.getUTCFullYear() +
    '-' +
    ('0' + (currentUtcDate.getUTCMonth() + 1)).slice(-2) +
    '-' +
    ('0' + currentUtcDate.getUTCDate()).slice(-2) +
    'T' +
    ('0' + currentUtcDate.getUTCHours()).slice(-2) +
    ':' +
    ('0' + currentUtcDate.getUTCMinutes()).slice(-2) +
    ':' +
    ('0' + currentUtcDate.getUTCSeconds()).slice(-2) +
    'Z'

  return dateString
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @param start The number from where to start
 * @param end The number where to end
 * @return array of numbers in that range
 */
const getNumbersArrBetween = (start: number, end: number): number[] => {
  let valueToReturn: number[] = []
  if (isNaN(start) || isNaN(end)) return valueToReturn
  valueToReturn.push(start)
  for (let i = start; i <= end; i++) valueToReturn.push(i + 1)
  return valueToReturn
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/** Converts all nt type dates to normal strings and returns obj with normal dates */
const getConvertedData = <Type extends IArgObj>(argsObj: Type, debugValue?: string): Type => {
  let finalObj = {}
  let objIsValid = argsObj && typeof argsObj === 'object'
  let objIsArray = Array.isArray(argsObj)

  if (typeof argsObj === 'string') return argsObj

  if (objIsValid && !objIsArray && !Object.keys(argsObj).length) return argsObj

  if (objIsValid && objIsArray) {
    if (debugValue === 'debug')
      argsObj = argsObj.map((curr: any) => {
        return getConvertedData({ curr }).curr
      })
    return argsObj
  }

  if (argsObj) {
    const objKeys = Object.keys(argsObj)
    const objValues = Object.values(argsObj)

    const isDate = (value: any) => {
      return value instanceof Date && _.isDate(value)
    }

    const getDate = (value: IDateInterace) => {
      if (value?.seconds) {
        value = `${new Date(value?.seconds * 1000)}` as any
      }
      value = `${new Date(value as any)}` as any
      return value
    }

    objKeys.forEach((curr, index) => {
      let value = objValues[index]

      if (typeof value === 'object' && value !== null && !Array.isArray(value))
        value = getConvertedData(value)
      if (Array.isArray(value)) value = value.map((currValue) => getConvertedData(currValue))

      let isTypeOfDate = isDate(value)

      if (value?.seconds || isTypeOfDate) {
        try {
          value = getDate(value)
        } catch (err) {
          value = `${new Date()}` as any
        }
      }

      finalObj = {
        ...finalObj,
        [curr]: value,
      }
    })
  }
  return finalObj as Type
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @param any Return date valid to react date picker
 */
const getReactPickerDate = (date: any) => {
  let date_
  let { date: date_copy } = getConvertedData({ date })
  try {
    if (date) {
      date_ = new Date(date_copy)
      if (date_ && isNaN(date_?.getDate())) return null
    } else throw new Error('Date not provided')
  } catch (err) {
    date_ = null
  }

  return date_
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/** Returns date of acc to firestore */
const getFirestoreDate = <Type extends Date>(
  data?: Type | null,
  allowAssignmentOfCurrDate?: boolean
): Type => {
  let dataToReturn
  if (!data) dataToReturn = allowAssignmentOfCurrDate === false ? '' : new Date()
  else {
    try {
      if ((data as any)?.seconds) {
        dataToReturn = new Date((data as any)?.seconds * 1000)
      } else dataToReturn = data ? new Date(`${data}`) : new Date(data)
    } catch (error) {
      dataToReturn = new Date()
    }
  }

  return dataToReturn as Type
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const getPublishedDetailsOfEvent = async (eventId: string) => {
  try {
    const doc = await FirestoreService.getItem(
      CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME,
      eventId
    )

    const eventSnap = await FirestoreService.getItem(
      CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENTS.NAME,
      eventId
    )

    const event = EventModel.fromFirestoreDoc(eventSnap).toObject()
    const selectedEventDetails = EventReviewPublishModel.fromFirestoreDoc(doc).toObject()
    const normalizedPublishDoc = getConvertedData(selectedEventDetails)

    return {
      basicEventDetails: getConvertedData({
        id: event.id,
        owner: event.owner,
        created: event.created,
        modified: event.modified,
        status: event.status ?? null,
        description: normalizedPublishDoc.EventDetails?.summaryLong ?? '',
        registrationPrice: null,
        registrationPriceAlias: null,
      }),
      Event: getConvertedData(event),
      EventFees: getConvertedData(new EventFeesModel(normalizedPublishDoc.EventFees).toObject()),
      EventStaff: getConvertedData(new EventStaffModel(normalizedPublishDoc.EventStaff).toObject()),
      EventVendors: getConvertedData(
        new EventVendorsModel(normalizedPublishDoc.EventVendors).toObject()
      ),
      EventTickets: getConvertedData(
        new EventTicketingModel(normalizedPublishDoc.EventTickets).toObject()
      ),
      EventDetails: getConvertedData(
        new EventDetailsModel(normalizedPublishDoc.EventDetails).toObject()
      ),
      EventSchedule: getConvertedData(
        new EventSchedulingModel(normalizedPublishDoc.EventSchedule).toObject()
      ),
      EventPolicies: getConvertedData(
        new EventPoliciesModel(normalizedPublishDoc.EventPolicies).toObject()
      ),
      EventSponsers: getConvertedData(
        new EventSponsorsModel(normalizedPublishDoc.EventSponsors).toObject()
      ),
      EventPaperwork: getConvertedData(
        new EventPaperworkModel(normalizedPublishDoc.EventPaperwork).toObject()
      ),
      EventPriceList: getConvertedData(event),
      EventRegisteredUsers: [],
      EventRecipients: [],
      EventSpectators: [],
      EventRegistrationTickets: [],
      EventPaymentSettings: getConvertedData(
        new EventPaymentSettingsModel(normalizedPublishDoc.EventPaymentSettings).toObject()
      ),
      EventRequiredFields: {
        requiredFields: event.requiredFields || [],
        requiredHorseFields: event.requiredHorseFields || [],
      },
      EventQuestions: {},
    }
  } catch (error) {
    helpers.logger({
      isError: true,
      message: error,
    })
    return null
  }
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
function calculateAge(birthday?: string | Date | null) {
  if (!birthday) return 0
  let currentTime = new Date().getTime()
  let birthDateTime = new Date(birthday).getTime()
  let difference = currentTime - birthDateTime
  var ageInYears = difference / (1000 * 60 * 60 * 24 * 365)
  return Math.floor(ageInYears)
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/** Returns code from select obj */
const selectObjToString = <Type extends any>(selectObj: Type): string => {
  let valueToReturn: any = ''
  let selectObjCopy = selectObj as ISelect

  if (selectObjCopy?.value) valueToReturn = selectObjCopy?.value
  else if (typeof selectObjCopy === 'string') valueToReturn = selectObjCopy
  else if (typeof Array.isArray(selectObjCopy)) valueToReturn = selectObjCopy
  else valueToReturn = ''

  return valueToReturn ?? ''
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @info Remove the specified keys passed
 * @param obj Its the object from which fields will be removed
 * @param fieldsToRemove Specifies the fields to be removed
 * @param remove if this is true, then all the fields will be removed except the passed ones
 */
const removeKeysFromObj = <T>(obj: T, fieldsToRemove: (keyof T)[], remove?: boolean): T => {
  let obj_ = cloneDeep(obj)

  if (
    !obj_ ||
    typeof obj_ !== 'object' ||
    !fieldsToRemove ||
    (fieldsToRemove && !Array.isArray(fieldsToRemove))
  )
    return obj_

  let allKeysOfObj = Object.keys(obj_) as (keyof T)[]

  if (remove)
    fieldsToRemove = allKeysOfObj.filter((curr) => {
      return !fieldsToRemove.includes(curr)
    })

  fieldsToRemove.forEach((currKeyToRemove) => {
    delete obj_?.[currKeyToRemove]
  })

  return obj_
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @info Takes obj having select values
 * @return Obj having string values
 */
const objsSelectValuesToString = <Type extends Record<any, any>>(ObjWithSelectVals: Type): Type => {
  let originalValue = ObjWithSelectVals
  let valueToReturn: any = {}

  if (!!!ObjWithSelectVals) return originalValue

  if (typeof ObjWithSelectVals === 'object' && Object.keys(ObjWithSelectVals).length) {
    valueToReturn = Object.keys(ObjWithSelectVals).reduce(
      (acc: any, currKey: any) => {
        if (typeof ObjWithSelectVals[currKey] === 'object') {
          acc[currKey] = selectObjToString(ObjWithSelectVals[currKey])
        } else acc[currKey] = ObjWithSelectVals[currKey]
        return acc
      },
      { ...ObjWithSelectVals }
    )
  }
  return typeof valueToReturn === 'object' && Object.keys(valueToReturn).length === 0
    ? originalValue
    : valueToReturn
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/** Returns selected value of react select */
const getValueFromSelectList: IGetValueFromListType = ({ list, valueToFind, valueToGet }) => {
  const foundValue = _.find(list, { value: valueToFind })
  if (!foundValue) return null
  else if (valueToGet && foundValue[valueToGet]) return foundValue[valueToGet]
  else return foundValue
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/** Returns whether date is valid or not */
const isValidDate = (year: number, month: number, day: number): boolean => {
  let month_ = month - 1
  const d = new Date(year, month_, day)
  const lsd = {
    year: d.getFullYear(),
    month: d.getMonth() + 1,
    day: d.getDate(),
  }
  let valueToReturn = lsd.year === year && lsd.month === month && lsd.day === day
  return valueToReturn
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @param teamMembers Takes an array of team members or an empty array
 * @return Structured team members
 */
const getStructuredTeamMembersList: IGetStructuredTeamMembersList = (teamMembers) => {
  let dataToReturn: ITeamMember[] = []
  dataToReturn = teamMembers
    .filter((teamMember) => teamMember?.memberId && teamMember.memberId !== '')
    .map((currTeamMember) => {
      return {
        memberId: currTeamMember?.memberId,
        memberRole: currTeamMember?.memberRole ?? '',
        defaultRole: currTeamMember?.defaultRole ?? '',
        memberStatus: currTeamMember?.memberStatus ?? '0',
        memberDob: currTeamMember?.memberDob ?? 'unknown',
        authorized: currTeamMember?.memberAuthorized === '1' ? 'authorized' : 'unauthorized',
        memberAuthorized: currTeamMember?.memberAuthorized ?? '0',
        memberName: currTeamMember?.memberName ?? 'unknown',
        memberEmail: currTeamMember?.memberEmail ?? 'unknown',
        memberAddress: currTeamMember?.memberAddress ?? 'unknown',
        memberPhoneNumber: currTeamMember?.memberPhoneNumber ?? '',
        memberShipActive: currTeamMember?.memberShipActive ?? false,
        memberProfilePicture: currTeamMember?.memberProfilePicture ?? '',
        memberProfileSynced: currTeamMember?.memberProfileSynced ?? false,
        memberSafeSupportTraining: currTeamMember?.memberSafeSupportTraining ?? false,
        memberCountry:
          (getValueFromSelectList({
            list: CountryList,
            valueToFind: currTeamMember?.memberCountry ?? '',
            valueToGet: 'label',
          }) as string) ??
          currTeamMember?.memberCountry ??
          '',
      }
    })

  return dataToReturn
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const getUserAsTeamMember = (user: IUserInterface, fieldsToOverWrite?: any): ITeamMember => {
  if (!fieldsToOverWrite || (fieldsToOverWrite && typeof fieldsToOverWrite !== 'object'))
    fieldsToOverWrite = {}

  return {
    horses: [],
    memberName: getUserFullName(user),
    selected: true,
    memberStatus: '1',
    memberId: user?.id,
    signedStatus: false,
    riderTeamMembers: [],
    memberAuthorized: '1',
    memberDob: user.userDOB,
    memberEmail: user.userEmail,
    memberRole: user.userDefaultRole,
    defaultRole: user.userDefaultRole,
    memberAddress: user.userAddress,
    memberProfilePicture: user.userProfilePicture,
    memberPhoneNumber: user.userPhoneNumber,
    ...fieldsToOverWrite,
  }
}

const getSelectedUserAsTeamMember = (
  user: IUserExtendedWithSelected,
  owner: IUserInterface
): IRegistrationTeamsTypes['IRegistrationTeamInterface'] => {
  return {
    userId: owner.id,
    eventId: null,
    registrationDocId: null,
    userName: getUserFullName(owner) ?? null,
    eventName: null,
    id: null,
    userNameNGram: owner.userNameNGram,
    memberProfilePicture: user.userProfilePicture,
    created: TimeLib.utcTimestamp(),
    modified: TimeLib.utcTimestamp(),
    memberAuthorized: '1',
    memberDob: user.userDOB,
    memberId: user.id,
    memberName: getUserFullName(user),
    memberRole: user.userDefaultRole,
    memberEmail: user.userEmail,
    selected: true,
  }
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @info Returns filtered data for my events tables
 */
const getMyEventsFilteredData = (eventsArr: IGetMyEventsFilteredDataItem[]): IMyEvent[] => {
  let valueToReturn: IMyEvent[] = []
  eventsArr?.forEach((data) => {
    valueToReturn.push({
      isDraft: data?.isDraft,
      eventId: data?.event?.id,
      eventLogo: data.eventLogo,
      eventCity: data?.event?.city,
      eventStartDate: data?.event?.eventStartDate,
      eventStatus: data?.event?.status,
      discipline: data?.event?.tags,
      eventState: data?.event?.state,
      eventName: data?.event?.eventName,
      eventCountry: data?.event?.country,
      eventCategory: data?.event?.category,
      created: data?.registration?.created,
      modified: data?.registration?.modified,
      registrationId: data?.registration?.id,
      waitlistCount: data?.event?.waitlistCount ?? 0,
      paymentStatus: data?.registration?.paymentStatus,
      registeredCount: data?.event?.registeredCountIncludingUnpaid,
      registrationAvailableCount: data?.event?.registrationAvailableCount,
      spotsRemaining:
        (data?.event?.registrationAvailableCount ?? 0) -
        data?.event?.registeredCountIncludingUnpaid,
    })
  })
  return valueToReturn
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * Returns a UTC timestamp,
 */
const utcTimestamp = () => {
  return new Date().toUTCString()
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const arrangeEventstaff = (EventStaff: IEventStaffInterface) => {
  let found: boolean = false
  let foundOnIndex: number = -1
  let icon: string | null = null
  let exitsInCategories: boolean = false
  let foundCategoryTitle: string | null = null
  let eventStaff = EventStaff.eventStaff ?? []
  let otherStaff = EventStaff.otherStaff ?? []
  let staffList: iesit['IStaffUiListItem'][] = []
  let staffListItem: iesit['IStaffUiListItem'] | null = null

  eventStaff.forEach((staffItem) => {
    icon = null
    foundOnIndex = -1
    exitsInCategories = !!staffCategoriesArray.find((currCategory, index) => {
      if (currCategory.category.includes(staffItem.title.toLocaleLowerCase())) {
        foundOnIndex = index
        icon = currCategory.logo
        foundCategoryTitle = currCategory.title
        return true
      }
      return false
    })

    found = foundOnIndex !== -1

    if (exitsInCategories && found) {
      staffItem.value.forEach((currStaffId) => {
        foundOnIndex = -1

        if (typeof currStaffId === 'string' && !!currStaffId) {
          staffListItem =
            staffList.find((currItem, currItemIndex) => {
              if (currItem.category.toLocaleLowerCase() !== staffItem.title.toLocaleLowerCase())
                return false
              foundOnIndex = currItemIndex
              return true
            }) ?? null

          found = foundOnIndex !== -1

          if (!staffListItem) {
            staffList.push({
              icon,
              category: helpers.capitalizeFirstLetter(foundCategoryTitle ?? '', true),
              staffs: [
                ...staffItem.value.map((currValue) => {
                  return {
                    staffId: currValue,
                    staffType: staffItem.title,
                  }
                }),
              ],
            })
          } else if (found) {
            staffList[foundOnIndex] = {
              ...staffList[foundOnIndex],
              staffs: [...staffList[foundOnIndex].staffs, ...staffItem.value] as any[],
            }
          }
        }
      })
    }
  })

  otherStaff.forEach((otherStaffItem) => {
    otherStaffItem.value.forEach((currStaffId) => {
      if (currStaffId) {
        // if (indexToAddOn === -1) indexToAddOn = staffList.length
        staffList.push({
          category: 'Other staffs',
          icon: staffCategoriesArray[0].logo,
          staffs: [
            ...(staffList[foundOnIndex]?.staffs ?? []),
            ...otherStaffItem.value.map((currValue) => {
              return {
                staffId: currValue,
                staffType: otherStaffItem.title,
              }
            }),
          ] as any,
        })
      }
    })
  })

  staffList = staffList.reduce(
    (acc: iesit['IStaffUiListItem'][], currItem) => {
      found = false
      foundOnIndex = -1
      acc.find((curr, index) => {
        if (curr.category === currItem.category) {
          foundOnIndex = index
          found = true
          return true
        }
        return false
      })

      if (found && acc?.[foundOnIndex]) {
        acc[foundOnIndex] = {
          ...acc[foundOnIndex],
          staffs: [...(acc[foundOnIndex].staffs || []), ...((currItem.staffs || []) as any)],
        }
      } else {
        acc.push({
          icon: currItem.icon,
          staffs: currItem.staffs,
          category: currItem.category,
        })
      }

      return acc
    },
    [] as iesit['IStaffUiListItem'][]
  )

  return staffList
}

const isMinorFn = (dob?: Date | string | null): boolean => {
  const MINORITY_AGE = 18
  let valueToReturn = false

  if (!dob) return valueToReturn

  return calculateAge(dob) < MINORITY_AGE
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

export const interfaceHelper = {
  getNotificationSettings,
  getFilteredHorseData,
  getFirestoreDate,
  getReactPickerDate,
  getStructuredTeamMembersList,
  getConvertedData,
  utcTimestamp,
}

export {
  isMinorFn,
  utcTimestamp,
  getSerializedRiderTeamMembers,
  arrangeEventstaff,
  calculateAge,
  getCategorizedEventDetails,
  getConvertedData,
  getGeohashRange,
  getMyEventsFilteredData,
  getNumbersArrBetween,
  getPublishedDetailsOfEvent,
  getReactPickerDate,
  getUserAsTeamMember,
  getUtcDate,
  getValueFromSelectList,
  isValidDate,
  objsSelectValuesToString,
  removeKeysFromObj,
  selectObjToString,
  getSelectedUserAsTeamMember,
}
