import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react'

import { Autorenew, Search } from '@mui/icons-material'
import { where } from 'firebase/firestore'
import _ from 'lodash'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import ModalComponent from '../events/views/details/global/modalComponent'
import SharePaperworkViaEmail from '../paperworks/SharePaperworkViaEmail'
import customImageComponent from '../common/CustomImageComponent'

import helpers from '../../commonHelpers/helpers'
import useToasterHelper from '../../helpers/ToasterHelper'
import { HandleModalContext } from '../../layout/mainlayout/MainLayout'
import { getUserFullName } from '../../helpers/helpers'

import { IPaperworkTab, ISignatory } from '../../models/event-drafts/event-draft.interface'
import { HorseModel } from '../../models/horse/horse.model'
import { IUserDocument } from '../../models/user-documents/user-documents.interface'
import { UserDocumentModel } from '../../models/user-documents/user-documents.model'
import { ITeamMember, IUserInterface } from '../../models/users/user.interface'
import FirestoreService from '../../services/firestoreService'
import { httpService } from '../../services/httpService'

import {
  selectEventDetails,
  selectRegisterEventPaperworksData,
} from '../../store/events/eventsSlice'
import { useAppSelector } from '../../store/hooks'
import { getSelectedHorse } from '../../store/horses/horseSlice'
import {
  selectDocSharingMails,
  setDocSharingMail,
  sharingMailData,
} from '../../store/paperworks/paperworkSlice'
import { selectProfileData, selectTeamMembers, selectUserId } from '../../store/user/userSlice'

import { CONST } from '../../const/const'
import { MODAL_CONSTS } from '../../const/modal-const'

import { ITeamMemberRoles } from '../../types/competitor_types'
import { UserModel } from '../../models/users/user.model'

const CHECKED_ICON = 'assets/cp_icons/Ok-3.svg'

// Types
type IViewModalProps = {
  show: boolean
  dataToPassOn: any
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  eventId?: any
}

type IModalType = {
  HORSE: boolean
  TEAM: boolean
  CLINIC_TABS: boolean
}

interface ITeamMembeWithSelected extends ITeamMember {
  selected?: boolean
}

const USERS_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS
type IGetSignatoryFn = (args: {
  paperworkDocumentKey: string
  includePersonsWithMailSent?: boolean
}) => ISignatory[]

function generateRandom(maxLimit = 3) {
  let rand = Math.random() * maxLimit
  rand = Math.floor(rand)
  return rand
}

const ShareDocumentModal = (props: IViewModalProps) => {
  //hooks and var
  const location = useLocation()
  const selecetdEvent = useAppSelector(selectEventDetails)
  const docSharingMails = useAppSelector(selectDocSharingMails)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const [step, setStep] = useState<string>('0')
  const searchBoxRef = useRef<HTMLInputElement>(null)
  const [listToShow, setListToShow] = useState<ITeamMembeWithSelected[]>()
  const [sameTypeUsers, setSameTypeUsers] = useState<ITeamMembeWithSelected[]>()
  const [teamMember, setTeamMember] = useState<ITeamMembeWithSelected[]>([])
  const showSearchList = searchBoxRef?.current?.value !== ''
  const userId = useAppSelector(selectUserId)
  const profileData = useAppSelector(selectProfileData)
  const selectedHorse = useAppSelector(getSelectedHorse)
  const paperworkTabDataInRedux: IPaperworkTab = useAppSelector(selectRegisterEventPaperworksData)
  const teamMembersList = useAppSelector(selectTeamMembers) as ITeamMembeWithSelected[]
  const toasterFunctions = useToasterHelper()
  const handleModalContext = useContext(HandleModalContext)
  const dataToPassOn = props?.dataToPassOn ?? {}

  useEffect(() => {
    if (dataToPassOn.data.documentShareViaEmail && dataToPassOn.data.documentShareViaEmail.length) {
      dispatch(setDocSharingMail(dataToPassOn.data.documentShareViaEmail))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataToPassOn.data])

  const types: IModalType = {
    HORSE: props?.dataToPassOn?.modal_type === 'forHorses',
    TEAM: props?.dataToPassOn?.modal_type === 'forTeam',
    CLINIC_TABS: props?.dataToPassOn?.modal_type === 'forClinicTabs',
  }

  // Functions
  /**
   * @info Closes the share document modal
   */
  const closeModal = () => {
    props?.handleModal(false, MODAL_CONSTS.SHARE_DOCUMENT)
  }

  useEffect(() => {
    if (searchBoxRef.current?.value) handleUsers(searchBoxRef.current?.value)
    setTeamMember([...(sameTypeUsers?.filter((curr) => curr?.selected === true) ?? [])])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sameTypeUsers])

  const getSignatories: IGetSignatoryFn = (args) => {
    const { paperworkDocumentKey } = args
    if (paperworkTabDataInRedux?.hasOwnProperty(paperworkDocumentKey)) {
      return (paperworkTabDataInRedux?.[paperworkDocumentKey] ?? []) as any[]
    }
    return []
  }

  const getAllUsers = async () => {
    const teamMembers: IUserInterface[] = []

    try {
      const usersSnaps = await FirestoreService.filterItems(USERS_COLLECTION.NAME)

      usersSnaps.forEach((user) => {
        const usersDetails = UserModel.fromFirestoreDoc(user).cloneDeep()
        teamMembers.push(usersDetails)
      })

      const roles = ['Rider', 'Trainer', 'Coach']

      let user: ITeamMembeWithSelected,
        list: ITeamMember[] = [],
        usersOfSameType: ITeamMembeWithSelected[] = [],
        horseMembersList = new HorseModel(selectedHorse ?? undefined)?.toObject()?.horseTeamMembers,
        signatoriesList: ITeamMembeWithSelected[] = getSignatories({
          paperworkDocumentKey: dataToPassOn?.paperworkDocumentKey,
          includePersonsWithMailSent: true,
        })

      list =
        (function () {
          if (types.HORSE) return horseMembersList
          if (types.TEAM) return teamMembersList
          if (types.CLINIC_TABS) return signatoriesList
          return []
        })() ?? []

      teamMembers.forEach((curr, index) => {
        if (curr?.id && curr?.id !== userId && !_.find(list, { memberId: curr?.id })) {
          user = {
            memberId: curr?.id ?? `${index}`,
            memberName: getUserFullName(curr),
            defaultRole: roles[generateRandom()] as ITeamMemberRoles,
            memberProfilePicture: curr.userProfilePicture ?? '',
            memberStatus: '1',
            memberprofileSynced: false,
            memberAddress: curr.userAddress ?? '',
            memberEmail: curr.userEmail ?? '',
            memberUserName: curr.userName ?? '',
            memberShipActive: false,
            memberSafeSupportTraining: false,
            selected: false,
            memberCountry: curr.userNationality ?? '',
          }
          usersOfSameType = [...usersOfSameType, user]
        }
      })

      setSameTypeUsers([...usersOfSameType])
    } catch (error) {
      console.log(error, 'error')
    }
  }

  // Gets the users of same type from db
  useEffect(() => {
    getAllUsers().then()
  }, [])

  // Filters the user in actual
  const handleUsers = (strToSearch: string) => {
    if (strToSearch === '') return setListToShow([])
    const filteredUserList = sameTypeUsers?.filter((curr) => {
      return (
        curr.memberName?.toLowerCase()?.includes(strToSearch?.toLowerCase()) ||
        curr.memberUserName?.toLowerCase()?.includes(strToSearch?.toLowerCase()) ||
        curr.memberEmail?.toLowerCase()?.includes(strToSearch?.toLowerCase())
      )
    })
    setListToShow([...(filteredUserList ?? [])])
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>, team_member: ITeamMembeWithSelected) => {
    if (sameTypeUsers) {
      const newCheckedUsers = sameTypeUsers.map((currUser) => {
        if (currUser.memberId === team_member?.memberId) currUser.selected = e.target.checked
        return currUser
      })
      setSameTypeUsers([...newCheckedUsers])
    }
  }

  const handleSubmit = async () => {
    try {
      if (location.pathname.includes('events-details')) {
        if (teamMember.length || step === '1') {
          const cond = docSharingMails.map((data: { email: any; selected: any }) => {
            return { memberEmail: data.email, selected: data.selected }
          })
          setLoading(true)

          let data = {
            emails:
              step === '1'
                ? [...cond]
                : teamMember.filter((mailer: ITeamMembeWithSelected) => {
                    return mailer.selected
                  }),
            senderData: {
              senderName: getUserFullName(profileData),
              senderEmail: profileData.userEmail,
            },
            events: selecetdEvent,
            eventUrl: window.location.href,
          }

          await httpService({ method: 'POST', url: 'send_event_mail', data })
          setLoading(false)
          toasterFunctions.info({ message: 'Event successfully shared' })
        }
      } else {
        if (step === '1') {
          let userDocumentSnap = await FirestoreService.getItem(
            CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS_DOCUMENTS.NAME,
            dataToPassOn.data.id
          )
          let userDocument = UserDocumentModel.fromFirestoreDoc(userDocumentSnap).toObject()
          // Fix to one time
          userDocument.documentShareViaEmail = docSharingMails as any
          await FirestoreService.updateItem(
            CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS_DOCUMENTS.NAME,
            dataToPassOn.data.id,
            userDocument
          )

          let data = {
            documentUrl: dataToPassOn.data.documentUrl,
            emails: docSharingMails.filter((mailer: sharingMailData) => {
              return mailer.selected
            }),
            senderData: {
              senderName: getUserFullName(profileData),
              senderEmail: profileData.userEmail,
            },
          }

          await httpService({ method: 'POST', url: 'send_document_via_email', data })

          setLoading(false)
          closeModal()
          return toasterFunctions.info({ message: 'Document shared successfully' })
        } else if (step === '0') {
          if (teamMember.length) {
            const USER_DOCUMENT = CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS_DOCUMENTS
            let sharingDocumentToActiveUser = async (updateData: IUserDocument) => {
              let isExist = await FirestoreService.filterItems(USER_DOCUMENT.NAME, [
                where('status', '==', 'Shared'),
                where(USER_DOCUMENT.FIELDS.DOCUMENT_OWNER.KEY, '==', userId),
                where(USER_DOCUMENT.FIELDS.ID.KEY, '==', dataToPassOn.data.id),
              ])

              if (!isExist.size) {
                await FirestoreService.createItem(
                  CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS_DOCUMENTS.NAME,
                  updateData
                )
              }
            }

            teamMember.forEach((teamMemberSingle: ITeamMembeWithSelected) => {
              let docData = {
                ...dataToPassOn.data,
                documentOwner: teamMemberSingle.memberId ?? '',
                status: 'Shared',
              }
              let updateData = new UserDocumentModel(docData).toObject()
              sharingDocumentToActiveUser(updateData)
            })

            toasterFunctions.info({ message: 'Document shared successfully' })
            handleModalContext?.handleModal(false, MODAL_CONSTS.SHARE_DOCUMENT)
          } else {
            handleModalContext?.handleModal(false, MODAL_CONSTS.SHARE_DOCUMENT)
            return toasterFunctions.success({ message: 'Document shared successfully' })
          }
        }
      }
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
    }
  }

  const getAnySelectedMailer = (data: sharingMailData[]) => {
    return data.filter((mailer: sharingMailData) => {
      return mailer.selected
    }).length
  }

  const copyTextToClipboard = (text: string) => {
    const input = document.createElement('textarea')
    input.value = text
    document.body.appendChild(input)
    input.select()
    try {
      const successful = document.execCommand('copy')

      successful
        ? toasterFunctions.success({ message: 'Saved to clipboard' })
        : toasterFunctions.error({ message: 'Unable to copy' })
    } catch (err) {
      toasterFunctions.error({ message: 'Unable to copy' })
    }
    document.body.removeChild(input)
  }

  return (
    <ModalComponent
      show={props.show}
      type={MODAL_CONSTS.SEE_MORE}
      onHide={closeModal}
      title={
        props?.eventId
          ? 'Share Event'
          : step === '0'
            ? 'Share'
            : step === '1'
              ? 'Add emails to send'
              : ''
      }
      headingClassName="text-[#122B46] !font-normal"
      size="md"
    >
      <div className="relative h-full flex flex-col">
        <div className="mr-2 relative overflow-y-auto">
          {step === '0' ? (
            <div>
              <div className="inputSearch py-2 flex items-center sticky top-0 bg-white">
                <input
                  disabled={loading}
                  ref={searchBoxRef}
                  type="text"
                  onChange={(e) => handleUsers(e.target.value)}
                  placeholder="Search..."
                  className="rounded-full w-full border focus:border-[#D3DAEE] border-[#D3DAEE] text-SeabiscuitDark200ThemeColor focus:ring-0"
                />
                <Search className="absolute text-SeabiscuitMainThemeColor right-6" />
              </div>
              <div className="min-h-[200px] py-4 pr-2">
                {(showSearchList ? listToShow : teamMember)?.length ? (
                  (showSearchList ? listToShow : teamMember)?.map((item, index) => {
                    return (
                      <label
                        key={index}
                        className="form-check !mb-4 cursor-pointer"
                        htmlFor={'teammember' + index}
                      >
                        <div className="user flex items-center rounded-lg py-1 w-full justify-between">
                          <div className="min-w-fit flex items-center">
                            <div className="w-[40px] h-[40px] min-w-[40px] rounded-full mr-2 overflow-hidden bg-gray-100">
                              {customImageComponent(item.memberProfilePicture, item.memberName)}
                            </div>
                            <div className="hourseDetails w-10/12">
                              <div className="hourseTitle text-SeabiscuitDark200ThemeColor">
                                <p className="text-SeabiscuitDark200ThemeColor text-lg">
                                  {item?.memberName}
                                </p>
                                <p className="text-xs">@{item?.memberUserName}</p>
                              </div>
                            </div>
                          </div>
                          {item.selected ? (
                            <img
                              src={CHECKED_ICON}
                              className={clsx('relative right-0 top-0', !item.selected && 'hidden')}
                              width={20}
                              height={20}
                              alt="tick"
                            />
                          ) : null}
                          <input
                            onChange={(e) => {
                              handleChange(e, item)
                            }}
                            checked={item?.selected}
                            id={'teammember' + index}
                            className={clsx(
                              'select-none ml-2 form-check-input appearance-none mr-[2px] h-4 w-4 border active:border-SeabiscuitMainThemeColor hover:border-SeabiscuitMainThemeColor focus:border-SeabiscuitMainThemeColor rounded-full focus:ring-0 focus:ring-offset-0 transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer right-0 checked:bg-SeabiscuitMainThemeColor checked:border-SeabiscuitMainThemeColor',
                              item.selected && 'hidden'
                            )}
                            type="checkbox"
                          />
                        </div>
                      </label>
                    )
                  })
                ) : (
                  <div className="w-full mt-2 text-center text-SeabiscuitDark200ThemeColor">
                    {showSearchList && 'No result found.'}
                  </div>
                )}
              </div>
            </div>
          ) : null}
          {step === '1' ? <SharePaperworkViaEmail data={docSharingMails} /> : null}
        </div>
        <div className="flex item-center flex-col w-full bg-[white] mt-auto sticky bottom-0">
          {location.pathname.includes('events-details') && (
            <div>
              {step === '0' ? (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      setStep('1')
                    }}
                    disabled={loading}
                    className="w-full py-4 mb-2 rounded-xl bg-SeabiscuitMainThemeColor hover:bg-[#D70443] text-SeabiscuitWhiteThemeColor"
                  >
                    Send Via Email
                  </button>
                </>
              ) : null}
            </div>
          )}

          {(step === '1' && getAnySelectedMailer(docSharingMails)) ||
          (step === '0' && teamMember.length) ? (
            <>
              <button
                type="button"
                disabled={loading}
                onClick={handleSubmit}
                className="w-full py-4 mb-2 rounded-xl bg-SeabiscuitMainThemeColor text-SeabiscuitWhiteThemeColor"
              >
                {loading ? <Autorenew className="animate-spin" /> : 'Send'}
              </button>
            </>
          ) : null}

          {step === '0' ? (
            <button
              type="button"
              onClick={() => copyTextToClipboard(window.location.href)}
              className="w-full py-4 mb-2 rounded-xl border border-SeabiscuitMainThemeColor hover:border-[#D70443] border-solid text-SeabiscuitMainThemeColor hover:text-[#D70443]"
            >
              Copy Link
            </button>
          ) : null}

          <button
            className="w-full py-4 rounded-xl bg-[#1F41731A] text-[#1F417380]"
            type="button"
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </div>
    </ModalComponent>
  )
}

export default ShareDocumentModal
