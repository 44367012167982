import { useMessageInputContext } from 'stream-chat-react'
import { ModalAttach } from '../modals/ModalAttach'
import { useState } from 'react'

export default function WriteMessage() {
  const { text, handleChange, handleSubmit } = useMessageInputContext()
  const [showAttachModal, setShowAttachModal] = useState(false)

  return (
    <>
      <div className="w-full flex flex-row pb-8 px-4 items-start mt-2">
        <img
          className="h-[30px] w-[30px] cursor-pointer pt-2 mr-2"
          src="/assets/chat/attach.svg"
          alt="searchIcon"
          onClick={() => setShowAttachModal(true)}
        />
        <div className="flex flex-row grow items-center border-2 border-[#122B461A]/[0.1] rounded-3xl focus-within:border-black focus-within:border-3 relative overflow-hidden">
          <textarea
            value={text}
            onChange={handleChange}
            className="font-Poppins placeholder:font-Poppins placeholder:font-normal grow px-4 resize-none outline-none focus:outline-none border-none focus:ring-0 focus:border-transparent py-2 placeholder-[#122B4680]/[0.5] text-[#122B46] placeholder:vertical-align"
            placeholder="Write a message"
            onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement>) => {
              if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault()
                handleSubmit()
              }
            }}
            rows={1}
            style={{ height: 'auto', minHeight: '40px', overflowY: 'hidden', lineHeight: '1.5' }}
            onInput={(event: React.FormEvent<HTMLTextAreaElement>) => {
              const target = event.target as HTMLTextAreaElement
              target.style.height = 'auto'
              target.style.height = `${target.scrollHeight}px`
            }}
          />
          {text.trim() && (
            <button onClick={handleSubmit} className="absolute right-2 bottom-2">
              <img
                className="bg-SeabiscuitMainThemeColor rounded-full h-[25px] w-[25px]"
                src="/assets/chat/gps.svg"
                alt="sendMessage"
              />
            </button>
          )}
        </div>
      </div>

      <ModalAttach show={showAttachModal} onHide={() => setShowAttachModal(false)} />
    </>
  )
}
