export default function NewMessage({ onClick }: { onClick: () => void }) {
  return (
    <button
      onClick={onClick}
      className="w-[154px] p-2 bg-[#F6F7FB] text-SeabiscuitDark200ThemeColor rounded-md flex flex-row items-center justify-center space-x-2"
    >
      New Message
    </button>
  )
}
