import { useEffect, useState, useRef } from 'react'

// Components
import NotifivationsListing from './NotifivationsListing'
import WrapperContainer from '../../components/common/wrappers/WrapperContainer'
import NotificationCell from './NotificationCell'

// Constants
import { PAPERWORK_DATA } from './data/tabs'

import ViewsLoader from '../../components/loader/ViewsLoader'
import { PillButtonElement } from '../../components/elements/buttons/pill/PillButtonElement'
import { NotificationFeed } from '@knocklabs/react'
import { useKnockClient, useKnockFeed } from '@knocklabs/react'
import { withKnockProvider } from '../../components/hoc/withKnockProvider'
import { FilterStatus } from '@knocklabs/react-core'
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

export type INotifivationSettingsDoc = {
  key: string
  on: boolean
  setting_logo: string
  setting_title: string
  setting_description: string
}

// type ICategorizedDocs = {
//     all: IPaperworkListingDoc[],
//     unRead: IPaperworkListingDoc[]
// }

const NotificationsRootPage = () => {
  // Hooks and vars
  const [tabs, setTabs] = useState({ ...PAPERWORK_DATA.TABS })
  const knockClient = useKnockClient()

  const [settingsData, setSettingsData] = useState<INotifivationSettingsDoc[]>([])
  const loading = useState(false)[0]
  const { feedClient } = useKnockFeed()
  const filterStatus =
    tabs.tablist[tabs.active] === 'Unread'
      ? FilterStatus.Unread
      : tabs.tablist[tabs.active] === 'Read'
        ? FilterStatus.Read
        : FilterStatus.All
  const [manageAllModalOpen, setManageAllModalOpen] = useState(false)
  const manageAllModalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    async function fetchPreferences() {
      const preferences = await knockClient.user.getPreferences()
      setSettingsData(
        PAPERWORK_DATA?.settingsList.map((currSetting) => ({
          ...currSetting,
          on: (preferences?.categories?.[currSetting.key] as boolean) ?? currSetting.on,
        }))
      )
    }
    fetchPreferences()
  }, [knockClient])

  const onToggle = async (key: string, on: boolean) => {
    knockClient.user.setPreferences({
      categories: { [key]: on },
      workflows: {},
      channel_types: {},
    })
    setSettingsData((prev) =>
      prev.map((curr) => ({
        ...curr,
        on: curr.key === key ? on : curr.on,
      }))
    )
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (manageAllModalRef.current && !manageAllModalRef.current.contains(event.target as Node)) {
        setManageAllModalOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <WrapperContainer title="Notifications">
      <>
        <div className="mb-4 flex items-center justify-between">
          <div>
            {tabs.tablist.map((currTab, tabIndex) => {
              return (
                <PillButtonElement
                  key={`${currTab}${tabIndex}`}
                  text={currTab}
                  Class="h-[45px] w-[120px] border-none inline-block"
                  onButtonClicked={() => setTabs({ ...tabs, active: tabIndex })}
                  value={tabIndex}
                  selected={tabIndex === tabs.active}
                />
              )
            })}
          </div>
          <div className="relative">
            <button className="text-gray-400" onClick={() => setManageAllModalOpen(true)}>
              Manage all
            </button>
            {manageAllModalOpen && (
              <div
                ref={manageAllModalRef}
                className="absolute right-0 mt-2 bg-white border border-gray-200 rounded-lg shadow-lg z-50 w-40"
              >
                {(tabs.tablist[tabs.active] === 'Unread' ||
                  tabs.tablist[tabs.active] === 'All') && (
                  <button
                    onClick={() => {
                      feedClient?.markAllAsRead()
                      setManageAllModalOpen(false)
                    }}
                    className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                  >
                    Mark all as read
                  </button>
                )}
                <button
                  onClick={() => {
                    feedClient?.markAllAsArchived()
                    setManageAllModalOpen(false)
                  }}
                  className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                >
                  Delete all
                </button>
              </div>
            )}
          </div>
        </div>
        {tabs.tablist[tabs.active] === 'Settings' && (
          <div className="mb-2 pt-6 pb-8">
            <h1 className="text-SeabiscuitDark200ThemeColor text-[19px] font-bold mb-1">
              Notification settings
            </h1>
            <h5 className="text-[#122B4680]/[.5]">Manage the notifications you wish to receive</h5>
            <div className="text-SeabiscuitLightParagraphTextColor capitalize font-normal">
              {tabs.tabDescriptionList[tabs.active]}
            </div>
          </div>
        )}

        {loading && (
          <ViewsLoader
            className="flex items-center w-full justify-center min-h-[30vh] mt-3"
            size="lg"
            color="#F70763"
          />
        )}
        {!loading && tabs.active !== 3 && (
          <NotificationFeed
            renderHeader={() => <></>}
            initialFilterStatus={filterStatus}
            renderItem={(props) => <NotificationCell {...props} filterStatus={filterStatus} />}
            EmptyComponent={
              <div className="text-center text-gray-400 h-full flex flex-col items-center justify-center">
                <span className="text-[14px] py-8">{tabs.tabEmptyList[tabs.active]}</span>
                <img
                  className="w-1/2 pb-16"
                  src={'/assets/no_notifications.png'}
                  alt="empty-state"
                />
              </div>
            }
          />
        )}
        {!loading && tabs.active === 3 && (
          <NotifivationsListing type={'Settings'} data={settingsData} onToggle={onToggle} />
        )}
      </>
    </WrapperContainer>
  )
}

export default withKnockProvider(NotificationsRootPage)
