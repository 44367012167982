import { NotificationCellProps, FilterStatus } from '@knocklabs/react'
import { IMAGE_CONSTS } from '../../const/image-const'
import { useRef, useMemo, useState, useEffect, useCallback } from 'react'
import { ContentBlock, MarkdownContentBlock, TextContentBlock } from '@knocklabs/client'
import { formatTimestamp, useTranslations } from '@knocklabs/react-core'
import { useKnockFeed } from '@knocklabs/react-core'

type BlockByName = {
  [name: string]: ContentBlock
}

function maybeNavigateToUrlWithDelay(url?: string) {
  if (url && url !== '') {
    setTimeout(() => window.location.assign(url), 200)
  }
}

const NotificationCell = ({
  item,
  filterStatus,
  onItemClick,
}: NotificationCellProps & { filterStatus: FilterStatus }) => {
  const actor = item.actors?.[0]
  const { locale } = useTranslations()
  const { feedClient } = useKnockFeed()

  const buttonRef = useRef<HTMLButtonElement>(null)
  const [openModal, setOpenModal] = useState(false)
  const modalRef = useRef<HTMLDivElement>(null)

  const blocksByName: BlockByName = useMemo(() => {
    return item.blocks.reduce((acc, block) => {
      return { ...acc, [block.name]: block }
    }, {})
  }, [item])
  const actionUrl = (blocksByName.action_url as TextContentBlock)?.rendered
  const onContainerClickHandler = useCallback(() => {
    // Mark as interacted + read once we click the item
    feedClient.markAsInteracted(item, {
      type: 'cell_click',
      action: actionUrl,
    })

    if (onItemClick) return onItemClick(item)

    return maybeNavigateToUrlWithDelay(actionUrl)
  }, [item, actionUrl, onItemClick, feedClient])

  const onKeyDown = useCallback(
    (ev: React.KeyboardEvent<HTMLDivElement>) => {
      switch (ev.key) {
        case 'Enter': {
          ev.stopPropagation()
          onContainerClickHandler()
          break
        }
        default:
          break
      }
    },
    [onContainerClickHandler]
  )

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setOpenModal(false)
      }
    }

    const handleMouseLeave = () => {
      setOpenModal(false)
    }

    const buttonElement = buttonRef.current
    buttonElement?.addEventListener('mouseleave', handleMouseLeave)

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      buttonElement?.removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [buttonRef])

  return (
    <div className="relative">
      <div
        onClick={onContainerClickHandler}
        onKeyDown={onKeyDown}
        className="flex flex-wrap justify-between gap-3 items-center w-full text-SeabiscuitDark200ThemeColor text-[14px] py-8 hover:bg-[#F1F3F8] px-4 group border-t border-b-1 border-[#D3DAEE]"
      >
        <div className="w-[25%] flex items-center rounded-[12px]">
          <span className="p-1 rounded-full shrink-0 mr-3 overflow-hidden flex items-center justify-center">
            <img
              src={
                actor && 'image' in actor && actor.image
                  ? actor.image
                  : IMAGE_CONSTS.PLACEHOLDERS.USER
              }
              onError={(e) => ((e.target as any).src = IMAGE_CONSTS.PLACEHOLDERS.USER)}
              className="w-11 h-11 rounded-full object-cover"
              alt="sender"
            />
          </span>

          <span className="capitalize text-ellipsis overflow-hidden whitespace-nowrap font-bold">
            {actor && 'name' in actor ? actor.name : 'no name'}
          </span>
        </div>

        <div className="flex-1 flex-col items-center rounded-[12px] text-ellipsis h-full">
          <span className=" text-[#122B4666]/[.4] text-[12px]">
            {formatTimestamp(item.inserted_at, { locale })}
          </span>
          {blocksByName.body && (
            <div
              className="capitalize text-ellipsis overflow-hidden whitespace-nowrap text-[14px]"
              dangerouslySetInnerHTML={{
                __html: (blocksByName.body as MarkdownContentBlock).rendered,
              }}
            />
          )}
        </div>

        <div className="hidden group-hover:flex group-hover:justify-end">
          <button
            ref={buttonRef}
            onClick={() => setOpenModal(true)}
            className="inline-flex w-[100px] h-[45px] mx-auto items-center justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-[14px] text-SeabiscuitDark200ThemeColor bg-transparent focus:outline-none ring-2 ring-offset-2 ring-[#D3DAEE]"
          >
            {`Manage`}
          </button>
        </div>
        <div className="flex justify-end items-center p-2 group-hover:hidden">
          {!item.read_at && <div className="w-3 h-3 bg-[#F7074F] rounded-full"></div>}
        </div>
      </div>
      {openModal && (
        <div
          className="absolute bg-white border border-gray-200 rounded-lg shadow-lg z-50 right-0 top-[100%]"
          ref={modalRef}
        >
          <button
            onClick={() => {
              if (filterStatus === FilterStatus.Unread) {
                feedClient?.markAsRead(item)
              } else {
                feedClient?.markAsUnread(item)
              }
              setOpenModal(false)
            }}
            className="block w-full text-left px-4 py-2 hover:bg-gray-100"
          >
            {filterStatus === FilterStatus.Unread ? 'Mark as Read' : 'Mark as Unread'}
          </button>
          <button
            onClick={() => {
              feedClient?.markAsArchived(item)
              setOpenModal(false)
            }}
            className="block w-full text-left px-4 py-2 hover:bg-gray-100"
          >
            Delete
          </button>
        </div>
      )}
    </div>
  )
}

export default NotificationCell
