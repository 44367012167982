import { IModelBaseModel } from '../model-base/model-base.interface'
import { ModelBaseModel } from '../model-base/model-base.model'
import { TEventRegisteredUsers } from './event-registered-users.interface'

export class EventRegisteredUsersModel
  extends ModelBaseModel<TEventRegisteredUsers>
  implements IModelBaseModel
{
  public v: TEventRegisteredUsers['v']
  public id: TEventRegisteredUsers['id']
  public owner: TEventRegisteredUsers['owner']
  public userId: TEventRegisteredUsers['userId']
  public userEmail: TEventRegisteredUsers['userEmail']
  public eventId: TEventRegisteredUsers['eventId']
  public draftId: TEventRegisteredUsers['draftId']
  public eventLat: TEventRegisteredUsers['eventLat']
  public userType: TEventRegisteredUsers['userType']
  public category: TEventRegisteredUsers['category']
  public userName: TEventRegisteredUsers['userName']
  public eventName: TEventRegisteredUsers['eventName']
  public eventLogo: TEventRegisteredUsers['eventLogo']
  public discipline: TEventRegisteredUsers['discipline']
  public eventState: TEventRegisteredUsers['eventState']
  public isScratched: TEventRegisteredUsers['isScratched']
  public isRegistered: TEventRegisteredUsers['isRegistered']
  public userNameNGrams: TEventRegisteredUsers['userNameNGrams']
  public paymentStatus: TEventRegisteredUsers['paymentStatus']
  public registrationDate: TEventRegisteredUsers['registrationDate']
  public userProfilePicture: TEventRegisteredUsers['userProfilePicture']
  public paperworkstatus: TEventRegisteredUsers['paperworkstatus']
  public paymentstatusall: TEventRegisteredUsers['paymentstatusall']
  public teamstatus: TEventRegisteredUsers['teamstatus']
  public answers: TEventRegisteredUsers['answers']

  public created: TEventRegisteredUsers['created']
  public modified: TEventRegisteredUsers['modified']

  public constructor(obj?: TEventRegisteredUsers) {
    super()
    this.v = obj?.v ?? 1
    this.id = obj?.id ?? null
    this.owner = obj?.owner ?? null
    this.userId = obj?.userId ?? null
    this.userEmail = obj?.userEmail ?? null
    this.draftId = obj?.draftId ?? null
    this.eventId = obj?.eventId ?? null
    this.eventLat = obj?.eventLat ?? null
    this.category = obj?.category ?? null
    this.userType = obj?.userType ?? null
    this.userName = obj?.userName ?? null
    this.eventName = obj?.eventName ?? null
    this.eventLogo = obj?.eventLogo ?? null
    this.userNameNGrams = this.getCalculatedNGrams(obj?.userName)
    this.discipline = obj?.discipline ?? []
    this.answers = obj?.answers ?? []
    this.eventState = obj?.eventState ?? null
    this.isRegistered = obj?.isRegistered ?? false
    this.paymentStatus = obj?.paymentStatus ?? null
    this.paymentstatusall = obj?.paymentstatusall ?? false
    this.paperworkstatus = obj?.paperworkstatus ?? false
    this.teamstatus = obj?.teamstatus ?? false
    this.isScratched = obj?.isScratched ?? false
    this.userProfilePicture = obj?.userProfilePicture ?? null

    this.registrationDate = this.utcTimestamp({
      key: 'registrationDate',
      value: obj?.registrationDate,
    })

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })
  }

  static fromFirestoreDoc(doc: any, registeredUsers?: number) {
    return new EventRegisteredUsersModel({
      id: doc.id,
      registeredUsers,
      ...doc.data(),
    })
  }
}
