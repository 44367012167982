import { MODAL_CONSTS } from '../../../const/modal-const'
import { useState, useRef, useEffect } from 'react'
import MainModal from '../../../components/modals/common/MainModal'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useVirtualizer } from '@tanstack/react-virtual'
import SearchBar from '../components/SearchBar'
import { CONST } from '../../../const/const'
import { UserModel } from '../../../models/users/user.model'
import { where } from 'firebase/firestore'
import { IUserInterface } from '../../../models/users/user.interface'
import FirestoreService from '../../../services/firestoreService'
import ProfileImage from '../components/ProfileImage'
import { getUserFullName } from '../../../helpers/helpers'
import { v4 as uuidv4 } from 'uuid'
import { useChatContext } from 'stream-chat-react'
import { ChannelData } from 'stream-chat'
import { useAppSelector } from '../../../store/hooks'
import { selectProfileData } from '../../../store/user/userSlice'

export function UserCard({
  className = '',
  selected,
  name,
  description,
  userProfilePicture,
}: {
  className?: string
  selected: boolean
  name: string
  description: string
  userProfilePicture?: string
}) {
  return (
    <div
      className={`flex flex-row w-full items-center justify-between py-3 caret-transparent ${className}`}
    >
      <div className="flex flex-row items-center gap-4">
        <ProfileImage
          src={userProfilePicture}
          lazyLoad={true}
          className={'h-[40px] w-[40px] object-cover rounded-full'}
        />

        <div className="grid gap-1">
          <div className="text-[14px] text-left text-[#122B46]">{name}</div>
          <div className="text-[12px] text-muted-foreground text-left text-[#122B46]/[0.7]">
            {description}
          </div>
        </div>
      </div>

      <img
        alt="Silhouette of a person's head"
        src={selected ? '/assets/chat/ok.svg' : '/assets/chat/filled_circle.svg'}
        className={`h-[20px] w-[20px] object-cover rounded-full`}
      />
    </div>
  )
}

const fetchUsers = async ({
  limit,
  offset,
  search,
}: {
  limit: number
  offset: string | null
  search?: string
}) => {
  const userSnapshots = await FirestoreService.filterItems(
    CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS.NAME,
    search
      ? [
          where(
            CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS.FIELDS.USER_NAME_NGRAMS.KEY,
            'array-contains',
            search
          ),
        ]
      : [],
    limit,
    null,
    null,
    offset
  )

  const users: IUserInterface[] = []
  let nextOffset: string | null = null
  if (userSnapshots.size) {
    userSnapshots.forEach((curr) => {
      users.push(UserModel.fromFirestoreDoc(curr).toObject())
    })
    nextOffset = userSnapshots.docs[userSnapshots.size - 1].id
  }
  return { users, nextOffset }
}

type Props = {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: {
    title?: string
    typeOfModal?: 'new_chat' | 'add_member'
  }
}

export default function MembersModal(props: Props) {
  const [selectedUsers, setSelectedUsers] = useState<
    { id: string; username: string; userFirstName: string; userFullName: string | null }[]
  >([])
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const USERS_PER_PAGE = 20
  const { status, data, error, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: ['users', search],
      queryFn: (ctx) => fetchUsers({ limit: USERS_PER_PAGE, offset: ctx.pageParam, search }),
      getNextPageParam: (lastPage) => {
        if (lastPage.users.length < USERS_PER_PAGE) return undefined
        return lastPage.nextOffset
      },
      initialPageParam: null as string | null,
    })
  const { client, setActiveChannel, channel: activeChannel } = useChatContext()
  const profileData = useAppSelector(selectProfileData)
  const typeOfModal = props.dataToPassOn.typeOfModal ?? 'new_chat'

  const toggleUserSelection = (user: {
    id: string
    username: string
    userFirstName: string
    userFullName: string | null
  }) => {
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.some((u) => u.id === user.id)
        ? prevSelectedUsers.filter((u) => u.id !== user.id)
        : [...prevSelectedUsers, user]
    )
  }

  const allRows = data ? data.pages.flatMap((d) => d.users) : []

  const parentRef = useRef<HTMLDivElement>(null)

  const onSubmit = async (
    users: { id: string; username: string; userFirstName: string; userFullName: string | null }[]
  ) => {
    setLoading(true)

    const otherUserIds = users.map((user) => user.id)
    if (typeOfModal === 'new_chat') {
      let channel
      const channelData: ChannelData = {}
      try {
        if (users.length === 1) {
          channel = client.channel('messaging', {
            members: [...otherUserIds, profileData.id],
            ...channelData,
          })
        } else if (users.length > 1) {
          const channelName =
            users.map((user) => user.userFirstName).join(', ') + `, ${profileData.userFirstName}`

          const channelId = uuidv4()
          channel = client.channel('messaging', channelId, {
            name: channelName,
            ...channelData,
          })
          await channel.create()
          await channel.addMembers([...otherUserIds, profileData.id])
        }

        await channel?.watch()
        await channel?.show()
        setActiveChannel(channel)
      } catch (error) {
        // TO DO: improve log error
        console.error(`Error creating chat: ${error}`)
      } finally {
        setLoading(false)
        setSelectedUsers([])
        props.handleModal(false, MODAL_CONSTS.MEMBERS_MODAL)
      }
    } else if (typeOfModal === 'add_member') {
      try {
        await activeChannel?.addMembers(otherUserIds)
      } catch (error) {
        // TO DO: improve log error
        console.error(`Error adding members to chat: ${error}`)
      } finally {
        setLoading(false)
        setSelectedUsers([])
        props.handleModal(false, MODAL_CONSTS.MEMBERS_MODAL)
      }
    } else {
      throw new Error('Invalid type of modal')
    }
  }

  const rowVirtualizer = useVirtualizer({
    count: hasNextPage ? allRows.length + 1 : allRows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 60,
    overscan: 5,
  })

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse()

    if (!lastItem) {
      return
    }

    if (lastItem.index >= allRows.length - 1 && hasNextPage && !isFetchingNextPage) {
      console.log('fetchNextPage!')
      fetchNextPage()
    }
  }, [
    hasNextPage,
    fetchNextPage,
    allRows.length,
    isFetchingNextPage,
    rowVirtualizer.getVirtualItems(),
  ])

  return (
    <MainModal
      size="md"
      title={props.dataToPassOn?.title ?? 'Select a member to message'}
      show={props.show}
      type="MEMBERS_MODAL"
      buttons={[
        {
          label: 'New Message',
          onClick: async () => {
            if (!selectedUsers.length) return
            await onSubmit(selectedUsers)
            setSelectedUsers([])
          },
          textClass: !selectedUsers.length ? 'text-[#F7074F]' : 'text-white',
          bgClass: !selectedUsers.length ? 'bg-white' : 'bg-SeabiscuitMainThemeColor',
          borderClass: !selectedUsers.length
            ? 'border-solid border border-[#F7074F]'
            : 'border-transparent',
          className: 'py-3 md:my-1 w-full rounded-xl md:w-[250px]',
        },
        {
          label: 'Cancel',
          onClick: () => {
            setSelectedUsers([])
            props.handleModal(false, MODAL_CONSTS.MEMBERS_MODAL)
          },
          className: 'py-3 md:my-1 w-full md:w-[250px]',
          bgClass: 'bg-[#1F41731A]/[.1]',
          textClass: 'text-[#1F417380]/[0.5]',
          borderClass: 'border-transparent rounded-xl',
          onHoverClass: 'hover:shadow-slate-300',
        },
      ]}
    >
      <div className="flex-1 flex-col w-full space-y-4 mt-2">
        <div className="flex flex-row space-x-2 rounded-full border border-[#D3DAEE]">
          <input
            type="text"
            placeholder="Search people..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="border-none p-2 rounded bg-transparent placeholder-[#122B4680]/[0.5] ml-4 text-[#122B46] flex-1 min-w-0"
          />
        </div>
        <div className="text-sm flex flex-row justify-between text-[#122B46]">
          <div>
            {selectedUsers.length} selected
            {selectedUsers.length > 0 && (
              <button onClick={() => setSelectedUsers([])} className="ml-2 text-blue-500 underline">
                Clear all
              </button>
            )}
          </div>
        </div>
        {status === 'pending' ? (
          <p>Loading...</p>
        ) : status === 'error' ? (
          <span>Error: {error.message}</span>
        ) : (
          <div ref={parentRef} className="h-[560px] w-full overflow-auto">
            <div
              style={{ height: `${rowVirtualizer.getTotalSize()}px` }}
              className={`relative w-full`}
            >
              {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                const isLoaderRow = virtualRow.index > allRows.length - 1
                const user = allRows[virtualRow.index]
                return (
                  <div
                    key={virtualRow.index}
                    onClick={() =>
                      toggleUserSelection({
                        id: user.id,
                        username: user.userName ?? '',
                        userFirstName: user.userFirstName ?? '',
                        userFullName: getUserFullName(user),
                      })
                    }
                    style={{
                      transform: `translateY(${virtualRow.start}px)`,
                      height: `${virtualRow.size}px`,
                    }}
                    className={`absolute top-0 left-0 w-full `}
                  >
                    {isLoaderRow ? (
                      hasNextPage ? (
                        'Loading more...'
                      ) : (
                        'Nothing more to load'
                      )
                    ) : (
                      <UserCard
                        className="h-full"
                        selected={selectedUsers.some((u) => u.id === user.id)}
                        userProfilePicture={user?.userProfilePicture}
                        name={getUserFullName(user)}
                        description={`@${user?.userName ?? ''}`}
                      />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        )}
        <div>{isFetching && !isFetchingNextPage ? 'Background Updating...' : null}</div>
      </div>
    </MainModal>
  )
}
