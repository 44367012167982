import { useContext } from 'react'
import { useAppSelector } from '../../store/hooks'
import { selectUserId } from '../../store/user/userSlice'
import WrapperContainer from '../../components/common/wrappers/WrapperContainer'
import { ChatContent, ChatFilters } from './components/DashboardChat'
import NewMessage from './components/NewMessage'
import ChatStream from './components/ChatStream'

import { HandleModalContext } from '../../layout/mainlayout/MainLayout'
import { MODAL_CONSTS } from '../../const/modal-const'
import { AllButton, UnreadButton } from './components/FilterButtons'

import 'stream-chat-react/dist/css/v2/index.css'
import './components/layout.css'

// Types
type Props = {
  handleModal: (showHide: boolean, typeOfModal: string, data?: Object) => void
}

const MessagesPage = ({ handleModal }: Props) => {
  const userId = useAppSelector(selectUserId)
  const handleModalContext = useContext(HandleModalContext)
  return (
    <WrapperContainer
      title="All Messages"
      noBg={true}
      removeShadow={true}
      removePadding={true}
      hideTitle={false}
    >
      {userId ? (
        <ChatContent userId={userId}>
          <div className="h-[86vh] w-full rounded-md px-4 bg-white pb-24">
            <div className="h-14 w-full flex flex-row items-center justify-between py-10 border-b-[1px] border-[#122B461A]/[0.1]">
              <ChatFilters>
                <AllButton />
                <UnreadButton />
              </ChatFilters>
              <div className="flex flex-row space-x-2">
                <NewMessage
                  onClick={() => {
                    handleModalContext?.handleModal(true, MODAL_CONSTS.MEMBERS_MODAL)
                  }}
                />
              </div>
            </div>

            <ChatStream />
            {/* <AddMemberModal /> */}
          </div>
        </ChatContent>
      ) : (
        <></>
      )}
    </WrapperContainer>
  )
}

export default MessagesPage
