import { Dispatch, SetStateAction, useContext } from 'react'
import {
  ChannelHeaderProps,
  useTranslationContext,
  useChannelPreviewInfo,
  DefaultStreamChatGenerics,
  useChannelStateContext,
} from 'stream-chat-react'
import { HandleModalContext } from '../../../layout/mainlayout/MainLayout'
import { IconPlus } from '../../../components/icons/IconPlus'
import { MODAL_CONSTS } from '../../../const/modal-const'
import { useChat } from './DashboardChat'
import './layout.css'

export default function CustomChannelHeader(props: ChannelHeaderProps) {
  const { image: overrideImage, live, title: overrideTitle } = props
  const { channel, watcher_count } =
    useChannelStateContext<DefaultStreamChatGenerics>('ChannelHeader')
  const { showProfile, setShowProfile } = useChat()

  const { t } = useTranslationContext('ChannelHeader')
  const { displayTitle } = useChannelPreviewInfo({
    channel,
    overrideImage,
    overrideTitle,
  })
  const handleModalContext = useContext(HandleModalContext)
  const { member_count, subtitle } = channel?.data || {}

  return (
    <div className="font-Poppins border-b-[1px] border-[#122B461A]/[0.1] str-chat__channel-header">
      <div className="str-chat__channel-header-end pl-8">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center space-x-2">
            <p className="str-chat__channel-header-title">
              {displayTitle}{' '}
              {live && (
                <span className="str-chat__header-livestream-livelabel">{t<string>('live')}</span>
              )}
            </p>

            {channel.data?.member_count && channel.data.member_count > 2 && (
              <button
                onClick={() =>
                  handleModalContext?.handleModal(true, MODAL_CONSTS.MEMBERS_MODAL, {
                    typeOfModal: 'add_member',
                  })
                }
              >
                <IconPlus />
              </button>
            )}
          </div>

          <button onClick={() => setShowProfile((s) => !s)}>
            <span className="text-[#122B46]/[0.5] pr-1 text-[14px]">
              {showProfile ? 'Close >>' : '<< More'}
            </span>
          </button>
        </div>
        {subtitle && <p className="str-chat__channel-header-subtitle">{subtitle}</p>}
        <p className="str-chat__channel-header-info">
          {!live && !!member_count && member_count > 0 && (
            <>
              {t('{{ memberCount }} members', {
                memberCount: member_count,
              })}
              ,{' '}
            </>
          )}
          {t<string>('{{ watcherCount }} online', { watcherCount: watcher_count })}
        </p>
      </div>
    </div>
  )
}
