import { useContext } from 'react'
import { useAppSelector } from '../../../../store/hooks'
import { selectUserId } from '../../../../store/user/userSlice'
import { ChatContent, ChatFilters } from '../../../messages/components/DashboardChat'
import {
  AllButton,
  ExhibitorsButton,
  StaffButton,
} from '../../../messages/components/FilterButtons'
import NewMessage from '../../../messages/components/NewMessage'
import ChatStream from '../../../messages/components/ChatStream'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import ChatHOC from '../../../messages/components/ChatHOC'
// Types
type Props = {
  eventId: string
}

const ManageClinicNOtherMessagesTab = (props: Props) => {
  const handleModalContext = useContext(HandleModalContext)
  const userId = useAppSelector(selectUserId)
  return (
    <ChatHOC>
      <div className="flex flex-col w-full rounded-md px-4 bg-white pb-24">
        {userId ? (
          <ChatContent userId={userId} eventId={props.eventId}>
            <div className="h-[70vh] w-full rounded-md px-4 bg-white pb-24">
              <div className="h-14 w-full flex flex-row items-center justify-between py-10 border-b-[1px] border-[#122B461A]/[0.1]">
                <ChatFilters>
                  <AllButton />
                  <ExhibitorsButton />
                  <StaffButton />
                </ChatFilters>

                <div className="flex flex-row space-x-2">
                  <NewMessage
                    onClick={() => {
                      handleModalContext?.handleModal(true, MODAL_CONSTS.MEMBERS_EVENT_MODAL, {
                        eventId: props.eventId,
                      })
                    }}
                  />
                </div>
              </div>

              <ChatStream />
            </div>
          </ChatContent>
        ) : (
          <></>
        )}
      </div>
    </ChatHOC>
  )
}

export default ManageClinicNOtherMessagesTab
