import { useState, useEffect } from 'react'
import type { ChannelSort, ChannelFilters, ChannelOptions } from 'stream-chat'
import {
  Channel,
  ChannelList,
  MessageInput,
  MessageList,
  Window,
  useChatContext,
} from 'stream-chat-react'
import EmptyChat from './EmptyChat'
import SearchBar from './SearchBar'
import WriteMessage from './WriteMessage'
import CustomChannelPreview from './CustomChannelPreview'
import CustomChannelHeader from './CustomChannelHeader'
import { CustomMessageUi } from './CustomMessage'
import { CustomDateSeparator } from './CustomDateSeparator'
import { useLocation } from 'react-router'
import ProfilePanel from './ProfilePanel'
import { useChat } from './DashboardChat'

export default function ChatStream() {
  const { chatFilters, eventId, userId, showProfile, setShowProfile } = useChat()
  const { setActiveChannel, client } = useChatContext()
  const [emptyChats, setEmptyChats] = useState<boolean | undefined>(undefined)
  const [query, setQuery] = useState('')
  const location = useLocation<{ channelId?: string }>()
  const channelId = location?.state?.channelId

  const sort: ChannelSort = { updated_at: -1 }
  let filters: ChannelFilters = {
    type: 'messaging',
    'member.user.name': query ? { $autocomplete: query } : undefined,
    name: query ? { $autocomplete: query } : undefined,
    members: { $in: [userId] },
    ...chatFilters,
    eventId: eventId ? { $eq: eventId } : undefined,
  }

  const options: ChannelOptions = {
    limit: 10,
  }

  useEffect(() => {
    async function fetchChats() {
      const all_filters: ChannelFilters = {
        type: 'messaging',
        members: { $in: [userId] },
      }
      return client.queryChannels(all_filters, undefined, { limit: 1 })
    }
    fetchChats().then((channels) => setEmptyChats(channels.length === 0))

    const response = client.on((event) => {
      if (event.type === 'notification.added_to_channel') {
        if (emptyChats) setEmptyChats(false)
      }

      if (event.type === 'channel.visible') {
        if (emptyChats) setEmptyChats(false)
      }

      if (event.type === 'channel.hidden') {
        fetchChats().then((channels) => setEmptyChats(channels.length === 0))
      }
    })

    return () => response.unsubscribe()
  }, [userId, emptyChats])

  useEffect(() => {
    if (channelId) {
      const channel = client.getChannelById('messaging', channelId, {}) //.queryChannels(filter, sort, { limit: 1 });
      setActiveChannel?.(channel)
    }
  }, [channelId, setActiveChannel])

  if (emptyChats === undefined) return null

  if (emptyChats) return <EmptyChat />

  return (
    <div className="flex flex-1 flex-row border-b-[1px] border-[#122B461A]/[0.1] h-full font-[Poppins]">
      <div className="flex flex-col basis-2/6 max-w-[400px] border-r-[1px] border-[#122B461A]/[0.1] h-full">
        <div className="p-4 border-b-[1px] border-[#122B461A]/[0.1]">
          <SearchBar search={query} setSearch={setQuery} />
        </div>

        <ChannelList
          filters={filters}
          sort={sort}
          options={options}
          EmptyStateIndicator={() => null}
          Preview={CustomChannelPreview}
        />
      </div>
      <div className="flex flex-row basis-4/6 grow">
        <Channel
          EmptyPlaceholder={<EmptyChat />}
          Message={CustomMessageUi}
          DateSeparator={CustomDateSeparator}
        >
          <Window>
            <CustomChannelHeader />
            <MessageList
              messageActions={['edit', 'delete']}
              threadList={true}
              groupStyles={(message, prev, next) => {
                if (
                  message.user?.id === prev?.user?.id &&
                  message.created_at &&
                  prev.created_at &&
                  (message.created_at as Date).getTime() - (prev.created_at as Date).getTime() <
                    60000
                ) {
                  return 'middle'
                } else if (
                  message.user?.id === next?.user?.id &&
                  message.user?.id !== prev?.user?.id &&
                  message.created_at &&
                  next.created_at &&
                  (next.created_at as Date).getTime() - (message.created_at as Date).getTime() <
                    60000
                ) {
                  return 'top'
                } else if (next === undefined) {
                  return 'bottom'
                } else {
                  return 'single'
                }
              }}
            />
            <MessageInput Input={WriteMessage} />
          </Window>
        </Channel>

        <ProfilePanel showProfile={showProfile} setShowProfile={setShowProfile} />
      </div>
    </div>
  )
}
