import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'
import { StreamChat } from 'stream-chat'
import FirebaseApp from '../../../services/firebaseApp'
import { httpsCallable } from 'firebase/functions'
import { selectProfileData, selectUserProfileImageUrl } from '../../../store/user/userSlice'
import { useAppSelector } from '../../../store/hooks'

interface ChatContextProps {
  client: StreamChat | null
}

const ChatContext = createContext<ChatContextProps | undefined>(undefined)

export const useChatClient = () => {
  const context = useContext(ChatContext)
  if (!context) {
    throw new Error('useChatClient must be used within a ChatProvider')
  }
  return context.client
}

interface ChatProviderProps {
  children: ReactNode
}

export const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
  const [client, setClient] = useState<StreamChat | null>(null)
  const profileData = useAppSelector(selectProfileData)
  const userProfileImageUrl = useAppSelector(selectUserProfileImageUrl)
  const userData = {
    id: profileData.id,
    name: profileData.userFirstName,
    image: userProfileImageUrl,
  }

  useEffect(() => {
    const chatClient = new StreamChat(process.env.REACT_APP_GETSTREAM_KEY!)
    let didUserConnectInterrupt = false

    const getStreamUserToken = httpsCallable(
      FirebaseApp.functions,
      'ext-auth-chat-getStreamUserToken'
    )

    const connectClient = async () => {
      try {
        const token = await getStreamUserToken().then((result) => result.data as string) // Asume una función para obtener el token
        await chatClient.connectUser(userData, token)
        if (!didUserConnectInterrupt) {
          setClient(chatClient)
        }
      } catch (error) {
        console.error('Error connecting to Stream Chat:', error)
      }
    }

    connectClient()

    return () => {
      didUserConnectInterrupt = true
      chatClient.disconnectUser().then(() => {
        console.log(`Disconnected user "${userData.id}"`)
      })
    }
  }, [userData.id])

  return <ChatContext.Provider value={{ client }}>{children}</ChatContext.Provider>
}
