import React, { ReactNode } from 'react'
import { Chat } from 'stream-chat-react'
import { useChatClient } from './withStreamClient'

interface ChatComponentProps {
  children?: ReactNode
  fallback?: ReactNode // Renderizado si no hay cliente
}

const ChatComponent: React.FC<ChatComponentProps> = ({ children, fallback = null }) => {
  const client = useChatClient()

  if (!client) {
    return null
  }

  return <Chat client={client}>{children}</Chat>
}

export default ChatComponent
